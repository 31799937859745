/* eslint-disable vars-on-top */
/* eslint-disable max-len */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable semi */
/* eslint-disable comma-dangle */
/* eslint-disable no-console */
/* eslint-disable indent */
/* eslint-disable block-scoped-var */

import Swiper from 'swiper/swiper-bundle.esm.js'
import 'swiper/swiper-bundle.min.css'

const initSwiper = () => {
  const swipers = []

  const aboutCorporSwiper = new Swiper('.about-corporate-swiper', {
    slidesPerView: 1,
    spaceBetween: 40,
    // slideToClickedSlide: true,
    allowTouchMove: true,
    watchSlidesProgress: true,
    pagination: {
      el: '.about-corporate-swiper-pagination',
      clickable: true
    },
    breakpoints: {
      1080: {
        allowTouchMove: false,
        effect: 'fade'
      }
    }
    // allowTouchMove: false,
  })
  const quickSmallSwiper = new Swiper('.quick-small-swiper', {
    spaceBetween: 0,
    slidesPerView: 6,
    slideToClickedSlide: false,
    watchSlidesProgress: true,
    allowTouchMove: false,
    observer: true,
    observeParents: true
  })
  const quickBigSwiper = new Swiper('.quick-big-swiper', {
    slidesPerView: 3,
    spaceBetween: 0,
    slideToClickedSlide: false,
    watchSlidesProgress: true,
    observer: true,
    observeParents: true,
    // allowTouchMove: false,
    grid: {
      // fill: 'col',
      rows: 2
    },
    on: {
      slideChange: function () {
        console.log('this.slidesPerView3333', this.slidesPerView)
        quickSmallSwiper.slideTo(this.activeIndex * 2, 1000, true)
      }
    }
  })
  const smallNext = document.querySelector('.quick-small-swiper-button-next')
  const smallPrev = document.querySelector('.quick-small-swiper-button-prev')
  if (smallNext || smallPrev) {
    smallNext.addEventListener('click', () => {
      console.log('2222')
      const currentBigI = quickBigSwiper.activeIndex
      const currentSmallI = quickSmallSwiper.activeIndex
      quickSmallSwiper.slideTo(currentSmallI + 6, 1000, true)
      quickBigSwiper.slideTo(currentBigI + 3, 1000, true)
    })
    smallPrev.addEventListener('click', () => {
      console.log('1111')
      const currentBigI = quickBigSwiper.activeIndex
      const currentSmallI = quickSmallSwiper.activeIndex
      quickSmallSwiper.slideTo(currentSmallI - 6, 1000, true)
      quickBigSwiper.slideTo(currentBigI - 3, 1000, true)
    })
  }
  if (document.querySelectorAll('.forum-swiper')) {
    // eslint-disable-next-line no-var
    var forumNum = document.querySelectorAll(
      '.forum-swiper .swiper-slide'
    ).length
  }
  swipers.push(
    new Swiper('.forum-swiper', {
      slidesPerView: 2,
      spaceBetween: 7,
      // autoplay: false,
      // eslint-disable-next-line block-scoped-var
      autoplay:
        forumNum > 4
          ? {
            delay: 4000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          }
          : false,
      loop: true,
      speed: 1000,
      pagination: {
        el: '.forum-swiper-pagination',
        clickable: true
      },
      breakpoints: {
        348: {
          autoplay: false,
          loop: false
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 7,
          autoplay: false,
          loop: false
        },
        1360: {
          slidesPerView: 4,
          spaceBetween: 7
        }
      }
    }),
    new Swiper('.quick-advantage-swiper', {
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: '.quick-advantage-swiper-pagination',
        clickable: true
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 20
        },
        1360: {
          slidesPerView: 4,
          spaceBetween: 40
        }
      }
    }),
    new Swiper('.blog-header-swiper', {
      speed: 400,
      slidesPerView: 1,
      spaceBetween: 0,
      autoHeight: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      observer: true,
      observeParents: true,
      pagination: {
        el: '.blog-header-swiper-pagination',
        clickable: true
      },
      breakpoints: {
        1280: {
          speed: 600
        }
      }
    })
  )
  swipers.push(
    new Swiper('.home-latest-swiper', {
      spaceBetween: 40,
      slidesPerView: 1,
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1280: {
          slidesPerView: 3
        }
      },
      observer: true,
      observeParents: true
    })
  )
  swipers.push(
    new Swiper('.home-banner-swiper', {
      speed: 600,
      slidesPerView: 1,
      spaceBetween: 10,
      parallax: true,
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 8000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // navigation: {
      //   nextEl: '.home-banner-swiper-button-next',
      //   prevEl: '.home-banner-swiper-button-prev',
      // },
      pagination: {
        el: '.home-banner-swiper-pagination',
        clickable: true
      },
      breakpoints: {
        1280: {
          speed: 1000
        }
      }
    }),
    new Swiper('.agg-workshop-swiper', {
      loop: true,
      speed: 600,
      slidesPerView: 1.2,
      spaceBetween: 20,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '.agg-workshop-swiper-button-next',
        prevEl: '.agg-workshop-swiper-button-prev'
      },
      breakpoints: {
        768: {
          speed: 600,
          slidesPerView: 2.5,
          spaceBetween: 20
        },
        1280: {
          speed: 1000,
          slidesPerView: 3,
          spaceBetween: 40
        },
        1920: {
          speed: 1000,
          slidesPerView: 'auto',
          spaceBetween: 60
        }
      }
    }),
    new Swiper('.homeContact-swiper', {
      loop: true,
      speed: 600,
      slidesPerView: 1,
      spaceBetween: 20,
      observer: true,
      observeParents: true,
    })
  )
  document.querySelectorAll('.agg-pro-max-swiper').forEach((e, i) => {
    swipers.push(
      new Swiper(`.agg-pro-max-swiper-${i}`, {
        speed: 600,
        slidesPerView: 1.1,
        spaceBetween: 10,
        navigation: {
          prevEl: `.agg-pro-swiper-button-${i}-prev`,
          nextEl: `.agg-pro-swiper-button-${i}-next`
        },
        breakpoints: {
          1280: {
            speed: 1000,
            slidesPerView: 1,
            spaceBetween: 20
          }
        },
        thumbs: {
          swiper: {
            el: `.agg-pro-min-swiper-${i}`,
            speed: 1000,
            spaceBetween: 30,
            slidesPerView: 5,
            watchSlidesVisibility: true
          },
          autoScrollOffset: 1
        }
      })
    )
  })
  const rowNum = document.querySelectorAll(
    '.about-focus-swiper .swiper-wrapper .swiper-slide'
  )?.length
  swipers.push(
    new Swiper('.about-focus-swiper', {
      slidesPerView: 1,
      allowTouchMove: false,
      grid: {
        fill: 'column',
        rows: rowNum
      },
      spaceBetween: 0,
      observeParents: true,
      breakpoints: {
        768: {
          slidesPerView: 2,
          allowTouchMove: true,
          grid: {
            fill: 'column',
            rows: 1
          }
        },
        1280: {
          slidesPerView: 3,
          allowTouchMove: true,
          grid: {
            fill: 'column',
            rows: 1
          }
        }
      },
      pagination: {
        el: '.about-focus-swiper-pagination',
        clickable: true
      }
    })
  )
  swipers.push(quickSmallSwiper)
  swipers.push(quickBigSwiper)
  // swipers.push(quickSmallSwiper);
  // swipers.push(aboutCorporSwiper);
  // const aggProMaxSwiper = new Swiper('.agg-pro-max-swiper', {
  //   speed: 1000,
  //   parallax: true,
  //   navigation: {
  //     prevEl: '.agg-pro-swiper-button-prev',
  //     nextEl: '.agg-pro-swiper-button-next',
  //   },
  //   thumbs: {
  //     swiper: {
  //       el: '.agg-pro-min-swiper',
  //       speed: 1000,
  //       spaceBetween: 30,
  //       slidesPerView: 5,
  //       watchSlidesVisibility: true,
  //     },
  //     autoScrollOffset: 1,
  //   },
  // });
  return { quickBigSwiper, aboutCorporSwiper }
}

export default initSwiper
